import React, { Component } from "react";
import "./NavbarLogo.css";
import kb from "../../../assets/kb.svg";
import { number } from "prop-types";

interface NavbarLogoProps {
  size: number;
}

class NavbarLogo extends Component<NavbarLogoProps> {
  render() {
    return (
      <div className="NavbarLogo" style={{ height: this.props.size + "px", width: this.props.size + "px" }}>
        <img src={kb} className="NavbarLogo-logo" alt="Logo KB" />
      </div>
    );
  }
}

export default NavbarLogo;
