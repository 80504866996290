import React, { Component } from "react";
import "./Navbar.css";
import NavbarLogo from "./NavbarLogo/NavbarLogo";
import NavbarLink from "./NavbarLink/NavbarLink";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

interface NavbarProps {
  deviceType: "landscape-desktop" | "landscape-mobile" | "portrait-desktop" | "portrait-mobile";
}

class Navbar extends Component<NavbarProps> {
  render() {
    const dvcType = this.props.deviceType;

    const size = dvcType === "landscape-desktop" ? 128 : 64;
    const hideLabel = dvcType === "landscape-desktop" ? false : true;
    const hamburger = dvcType === "landscape-desktop" ? false : true;

    return (
      <div className={"Navbar " + dvcType}>
        <Link to="/">
          <NavbarLogo size={size} />
        </Link>
        <div className={"Navbar-linksContainer " + dvcType}>
          <NavbarLink
            icon={Icon.BookOpen}
            size={size}
            label="portfolio"
            hideLabel={hideLabel}
            activeFillColor="#5809ff"
            link="https://www.freelancer.com/u/kaykyb"
            useAnchor
          />

          <NavbarLink
            icon={Icon.Smile}
            size={size}
            label="contact"
            hideLabel={hideLabel}
            link="/contact"
            activeFillColor="#90A4AE"
          />
        </div>

        <NavbarLink
          icon={Icon.GitHub}
          size={size}
          label="github"
          hideLabel={hideLabel}
          link="https://github.com/kaykyb"
          activeFillColor="#ff0047"
          useAnchor
        />

        {/* {hamburger && (
          <NavbarLink icon={Icon.Menu} size={size} label="menu" hideLabel={hideLabel} link="/menu" />
        )}
        {!hamburger && (
          <NavbarLink
            icon={Icon.GitHub}
            size={size}
            label="github"
            hideLabel={hideLabel}
            link="https://github.com/kaykyb"
            useAnchor
          />
        )} */}
      </div>
    );
  }
}

export default Navbar;
