import React, { Component, ReactNode } from "react";
import "./SceneContent.css";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import * as Icons from "react-feather";

interface SceneContentProps {
  text: string;
  textColor?: string;
  buttonText: string;
  buttonBackgroundColor?: string;
  buttonForegroundColor?: string;
  link: string;
  useAnchor?: boolean;
}

class SceneContent extends Component<SceneContentProps> {
  render() {
    let style = {};

    if (this.props.textColor) {
      style = { color: this.props.textColor };
    }

    return (
      <div className="SceneContent">
        <h2 style={style}>
          {this.props.text.split("\n").map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        </h2>

        {!this.props.useAnchor && (
          <Link to={this.props.link}>
            <PrimaryButton
              label={this.props.buttonText}
              backgroundColor={this.props.buttonBackgroundColor}
              foregroundColor={this.props.buttonForegroundColor}
              icon={Icons.ArrowRight}
            />
          </Link>
        )}

        {this.props.useAnchor && (
          <a href={this.props.link}>
            <PrimaryButton
              label={this.props.buttonText}
              backgroundColor={this.props.buttonBackgroundColor}
              foregroundColor={this.props.buttonForegroundColor}
              icon={Icons.ArrowRight}
            />
          </a>
        )}
      </div>
    );
  }
}

export default SceneContent;
