import React, { Component } from "react";

import "./Portfolio.css";

interface PortfolioProps {
  deviceType: "landscape-desktop" | "landscape-mobile" | "portrait-desktop" | "portrait-mobile";
}

class Portfolio extends Component<PortfolioProps> {
  render() {
    return <div className="Portfolio">Portfolio</div>;
  }
}

export default Portfolio;
