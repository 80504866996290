import React, { Component } from "react";
import * as Icons from "react-feather";

import "./PrimaryButton.css";

interface PrimaryButtonProps {
  label: string;
  icon?: React.ComponentType<Icons.Props>;
  backgroundColor?: string;
  foregroundColor?: string;
}

class PrimaryButton extends Component<PrimaryButtonProps> {
  render() {
    let style = {};

    if (this.props.backgroundColor) {
      style = { background: this.props.backgroundColor };
    }

    if (this.props.foregroundColor) {
      style = { ...style, color: this.props.foregroundColor };
    }

    return (
      <button className="PrimaryButton" style={style}>
        {this.props.label}
        {this.props.icon && (
          <div className="PrimaryButton-icon">
            <this.props.icon strokeWidth={1} />
          </div>
        )}
      </button>
    );
  }
}

export default PrimaryButton;
