import React, { Component } from "react";

import "./Contact.css";

import * as Icons from "react-feather";

export default class Contact extends Component {
  render() {
    return (
      <div className="Contact">
        <div className="contact-info">
          <h3>Contact</h3>
          <div className="contact-item">
            <Icons.Mail strokeWidth="1" />
            &nbsp;&nbsp;hello@kaykyb.com
          </div>
          <div className="contact-item">
            <Icons.GitHub strokeWidth="1" />
            &nbsp;&nbsp;kaykyb
          </div>
          <div className="contact-item">
            <Icons.Twitter strokeWidth="1" />
            &nbsp;&nbsp;okaykyb
          </div>
        </div>
      </div>
    );
  }
}
