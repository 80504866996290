import React, { Component } from "react";
import "./HomeScene.css";
import SceneImage from "./SceneImage/SceneImage";

import SceneContent from "./SceneContent/SceneContent";

interface SceneDetails {
  img: string;
  animationDelay: number;
  animationDuration: number;
}

interface HomeSceneProps {
  text: string;
  textColor?: string;
  buttonText: string;
  buttonBackgroundColor?: string;
  buttonForegroundColor?: string;
  scenes: SceneDetails[];
  firstGradientStop: string;
  lastGradientStop: string;
  deviceType: "landscape-desktop" | "landscape-mobile" | "portrait-desktop" | "portrait-mobile";
  link: string;
  useAnchor?: boolean;
}

class HomeScene extends Component<HomeSceneProps> {
  render() {
    const col = this.props.deviceType === "landscape-desktop" || this.props.deviceType == "landscape-mobile";
    return (
      <div
        className={"HomeScene " + (col ? "col" : "row")}
        style={{
          backgroundImage:
            "linear-gradient(45deg, " + this.props.firstGradientStop + ", " + this.props.lastGradientStop
        }}
      >
        {this.props.scenes.map((v, k) => (
          <SceneImage
            img={v.img}
            animationDelayMs={v.animationDelay}
            animationDurationMs={v.animationDuration}
            key={k}
          />
        ))}

        <SceneContent
          link={this.props.link}
          text={this.props.text}
          textColor={this.props.textColor}
          buttonText={this.props.buttonText}
          buttonBackgroundColor={this.props.buttonBackgroundColor}
          buttonForegroundColor={this.props.buttonForegroundColor}
          useAnchor={this.props.useAnchor}
        />
      </div>
    );
  }
}

export default HomeScene;
