import React, { Component } from "react";
import HomeScene from "../../components/Scene/HomeScene";
import "./Home.css";

// newLight
import newLight from "../../assets/telurNewLight/telurNew.png";

// scene 1
import code from "../../assets/sceneOne/code.png";

// scene 2
import bcb from "../../assets/sceneTwo/blue-circle-bottom.png";
import bct from "../../assets/sceneTwo/blue-circle-top.png";
import ytf from "../../assets/sceneTwo/yellow-triangle-filled.png";
import yt from "../../assets/sceneTwo/yellow-triangle.png";

// scene 3
import face from "../../assets/sceneThree/face.png";
import bubbles from "../../assets/sceneThree/bubbles.png";
import binary from "../../assets/sceneThree/binary.png";
import bhaskara from "../../assets/sceneThree/bhaskara.png";

interface HomeProps {
  deviceType: "landscape-desktop" | "landscape-mobile" | "portrait-desktop" | "portrait-mobile";
}

class Home extends Component<HomeProps> {
  private rootDiv?: HTMLDivElement | null;

  constructor(props: HomeProps) {
    super(props);

    this.handleRootWheel = this.handleRootWheel.bind(this);
  }

  render() {
    return (
      <div
        className={"Home " + this.props.deviceType}
        onWheel={this.handleRootWheel}
        ref={div => (this.rootDiv = div)}
      >
        <HomeScene
          deviceType={this.props.deviceType}
          text={`Creating\nwith code.`}
          buttonText="Projects"
          scenes={[
            {
              img: code,
              animationDelay: 0,
              animationDuration: 0
            }
          ]}
          firstGradientStop="#000000"
          lastGradientStop="#010d34"
          link="https://github.com/kaykyb"
          useAnchor
        />
        <HomeScene
          deviceType={this.props.deviceType}
          text={`Designing\nwith love.`}
          buttonText="Portfolio"
          scenes={[
            {
              img: bcb,
              animationDelay: 0,
              animationDuration: 0
            },
            {
              img: yt,
              animationDelay: 0,
              animationDuration: 7000
            },
            {
              img: bct,
              animationDelay: 0,
              animationDuration: 5000
            },
            {
              img: ytf,
              animationDelay: 0,
              animationDuration: 6000
            }
          ]}
          firstGradientStop="#3C0070"
          lastGradientStop="#0800FF"
          buttonBackgroundColor="#FFF700"
          buttonForegroundColor="#000000"
          link="https://www.freelancer.com/u/kaykyb"
          useAnchor
        />
        {/* TELURIO MARKETING */}
        <HomeScene
          deviceType={this.props.deviceType}
          text={`Telurio.\nThe open editor.`}
          buttonText="Contribute"
          scenes={[
            {
              img: newLight,
              animationDelay: 0,
              animationDuration: 0
            }
          ]}
          firstGradientStop="#000000"
          lastGradientStop="#000000"
          buttonBackgroundColor="#ff0000"
          link="https://github.com/kaykyb/telurio-bootstrap"
          useAnchor
        />

        <HomeScene
          deviceType={this.props.deviceType}
          text={`About math,\ntech and passion.`}
          buttonText="Hire Me"
          scenes={[
            {
              img: bhaskara,
              animationDelay: 0,
              animationDuration: 8000
            },
            {
              img: bubbles,
              animationDelay: 0,
              animationDuration: 7000
            },
            {
              img: face,
              animationDelay: 0,
              animationDuration: 0
            },
            {
              img: binary,
              animationDelay: 0,
              animationDuration: 6000
            }
          ]}
          firstGradientStop="#FFFFFF"
          lastGradientStop="#FFFFFF"
          buttonBackgroundColor="#000000"
          buttonForegroundColor="#00BEF9"
          textColor="#00BEF9"
          link="https://www.freelancer.com/u/kaykyb"
          useAnchor
        />
      </div>
    );
  }

  handleRootWheel(ev: React.WheelEvent<HTMLDivElement>) {
    if (this.props.deviceType === "landscape-desktop" && this.rootDiv) {
      this.rootDiv.scrollBy({ left: ev.deltaY / 2, behavior: "auto" });
      ev.preventDefault();
    }
  }
}

export default Home;
