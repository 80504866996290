import React, { Component } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Route } from "react-router-dom";

// views
import Home from "./views/Home/Home";
import Portfolio from "./views/Portfolio/Portfolio";
import Contact from "./views/Contact/Contact";
// import LanguageSelection from "./views/LanguageSelection/LanguageSelection";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App-container only-landscape only-desktop">
          {this.getApp("landscape-desktop", "only-landscape only-desktop")}
        </div>
        <div className="App-container only-landscape only-mobile">
          {this.getApp("landscape-mobile", "only-landscape only-mobile")}
        </div>
        <div className="App-container only-portrait only-desktop">
          {this.getApp("portrait-desktop", "only-portrait only-desktop")}
        </div>
        <div className="App-container only-portrait only-mobile">
          {this.getApp("portrait-mobile", "only-portrait only-mobile")}
        </div>
      </Router>
    );
  }

  getApp(
    deviceType: "landscape-desktop" | "landscape-mobile" | "portrait-desktop" | "portrait-mobile",
    adendos: string
  ) {
    const navbarOnSide = deviceType === "landscape-desktop" || deviceType === "landscape-mobile";

    return (
      <>
        {/* <LanguageSelection deviceType={deviceType} /> */}
        {!navbarOnSide && <Navbar deviceType={deviceType} />}
        <div className={"App " + adendos}>
          {navbarOnSide && <Navbar deviceType={deviceType} />}
          <Route path="/" exact render={() => <Home deviceType={deviceType} />} />
          <Route path="/portfolio" exact render={() => <Portfolio deviceType={deviceType} />} />
          <Route path="/contact" exact render={() => <Contact />} />
        </div>
      </>
    );
  }
}

export default App;
