import React, { Component } from "react";
import "./SceneImage.css";

interface SceneImageProps {
  img: string;
  animationDelayMs: number;
  animationDurationMs: number;
}

class SceneImage extends Component<SceneImageProps> {
  render() {
    return (
      <div
        className="SceneImage"
        style={{
          backgroundImage: "url(" + this.props.img + ")",
          animationDelay: this.props.animationDelayMs + "ms",
          animationDuration: this.props.animationDurationMs + "ms"
        }}
      />
    );
  }
}

export default SceneImage;
