import React, { Component } from "react";
import "./NavbarLink.css";
import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";

interface NavbarLinkProps {
  size: number;
  icon: React.ComponentType<Icon.Props>;
  label: string;
  hideLabel?: boolean;
  link: string;
  useAnchor?: boolean;
  activeFillColor?: string;
}

class NavbarLink extends Component<NavbarLinkProps> {
  render() {
    return (
      <div className="NavbarLink" style={{ height: this.props.size + "px", width: this.props.size + "px" }}>
        {!this.props.useAnchor && (
          <NavLink
            to={this.props.link}
            activeClassName="active"
            activeStyle={{ fill: this.props.activeFillColor || "blue" }}
          >
            {this.getItem()}
          </NavLink>
        )}
        {this.props.useAnchor && <a href={this.props.link}>{this.getItem()}</a>}
      </div>
    );
  }

  getItem() {
    return (
      <>
        <div className="NavbarLink-iconContainer">
          <this.props.icon size={(this.props.size / 64) * 24} strokeWidth={1} />
        </div>
        {!this.props.hideLabel && <div className="NavbarLink-label">{this.props.label}</div>}
      </>
    );
  }
}

export default NavbarLink;
